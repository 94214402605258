#layout-wraper {
  min-width: 1440px;
  &.collapsed {
    .layout-wraper-sider {
      .ant-row-space-between {
        justify-content: center;
      }
      .icon-logo {
        display: none;
      }
      .icon-collapsed {
        transform: rotate(180deg);
      }
    }
    .layout-wraper-header {
      width: calc(100% - 80px);
      left: 80px;
    }
    .layout-wraper-content {
      margin-left: 80px;
    }
  }

  &.uncollapsed {
    .layout-wraper-header {
      width: calc(100% - 240px);
      left: 240px;
    }
    .layout-wraper-content {
      margin-left: 240px;
    }
    .icon-collapsed {
      position: absolute;
      right: 10px;
    }
  }

  .layout-wraper-header {
    position: fixed;
    top: 0;
    left: 0;
    border-bottom: 1px solid #dbdbdb;
    z-index: 99;
    transition: all 0.2s;
  }

  .layout-wraper-sider {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100vh;
    z-index: 999;
    .sider-logo {
      height: 64px;
      padding: 0 20px;
      border-bottom: 1px solid #ffffff;
      position: relative;
    }
    .sider-menu {
      margin-top: 20px;
      border-right: none;
      background-color: inherit;
      overflow-y: auto;
      height: calc(100vh - 84px);
      &::-webkit-scrollbar {
        width: 0;
      }
      .ant-menu-item {
        height: 56px;
        margin: 0;
        font-weight: 700;
        border-bottom: 1px solid #ffffff;
        color: #fff;
        &.ant-menu-item-selected {
          background-color: #ffe999;
          color: #002642;
        }
      }
      .ant-menu-submenu {
        .ant-menu-item {
          background-color: #d2e9fa;
          border: none;
          &.ant-menu-item-selected {
            background-color: #0074bf;
            color: #ffffff;
          }
        }
      }
      .ant-menu-title-content {
        font-weight: 700;
      }
    }
    // todo: wait design icon
    .ant-menu-inline-collapsed {
      display: none;
    }
  }
  .layout-wraper-content {
    margin-top: 64px;
  }
}

.modal-wrapper {
  .ant-modal-content .ant-modal-body {
    padding: 0;
  }
}
